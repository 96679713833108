<template>
  <div class='container'>
    404
  </div>
</template>

<script>
export default {
  data(){
    return{
 
    }
  },
  created(){
 
  },
  methods:{
 
  }
}
</script>
<style lang='less' scoped>
</style>